/**
 * main.js
 * contains Javascripts for the whole website
 *
 * @author J.Hahn <j.hahn@agentur-halma.de>
 * @version 2017-08-11
 */

var Eton = {

	getFullpageSectionDataByIndex: function(index) {
		
		var sections = $('.fp-section');
		var section = sections[index-1];
		var classNames = ($(section).attr('class')).split(' ');
		
		for (a in classNames) {
			
			if (classNames[a].indexOf('background-') != -1) {
				var backgroundType = classNames[a].split('-')[1];
				break;
			}
		}
		
		// get previous and next sections' titles
		var prevTitle = $(sections[index-2]).data('title');
		var nextTitle = $(sections[index]).data('title');
		
		// check if section has slides
		var hasSlides = false;
		
		if (jQuery('.slide', section).length != 0) {
			hasSlides = true;
		}
		
		prevTitle = typeof prevTitle != 'undefined' ? prevTitle : '';
		nextTitle = typeof nextTitle != 'undefined' ? nextTitle : '';
		
		return {
			backgroundType: backgroundType,
			classNames: classNames,
			title: $(section).data('title'),
			prevTitle: prevTitle,
			nextTitle: nextTitle,
			hasSlides: hasSlides,
			id: $(section).attr('id')
		}
	},
	
	moveMultipleSlidesRight: function() {
		$.fn.fullpage.setScrollingSpeed(100);
		window.setTimeout(
			function() {
				$.fn.fullpage.moveSlideRight();
				//$.fn.fullpage.setScrollingSpeed(700);

			}, 
			150
		);
	},

	moveMultipleSlidesLeft: function() {
		$.fn.fullpage.setScrollingSpeed(100);
		window.setTimeout($.fn.fullpage.moveSlideLeft, 150);

	},
	
	activate: function(el) {
		
		$(el).removeClass('element-inactive');
		
		if (!$(el).hasClass('element-active')) {
			$(el).addClass('element-active');
		}
	},
	
	deactivate: function(el) {
		
		$(el).removeClass('element-active');
		
		if (!$(el).hasClass('element-inactive')) {
			$(el).addClass('element-inactive');
		}
	}
}
 
