/**
 * homepage.js
 * contains Javascripts for the homepage
 *
 * @author J.Hahn <j.hahn@agentur-halma.de>
 * @version 2017-08-11
 */

$(document).ready(function() {

	if ($('body.page-12').length != 0) {
		return;
	}

	// gather data from sections for tooltips
	var tooltips = []
	$('.section').each(function(index, value) {
		tooltips.push($(this).data('title'))
	}) 

	/*
	 *  initialize fullpage.js
	 */
	$('#fullpage').fullpage({
		'verticalCentered': false,
		'css3': true,
		'navigation': true,
		'navigationPosition': 'right',
		'navigationTooltips': tooltips,
		
		/*
		 * Event "on leave asection" for every section
		 */
		'onLeave': function(index, nextIndex, direction){

			$('body').removeClass('fp-section-background-' + $('body').data('fpSectionBackgroundType'));
			
			var sectionData = Eton.getFullpageSectionDataByIndex(nextIndex);

			$('body').addClass('fp-section-background-' + sectionData.backgroundType);
			
			$.fn.fullpage.setScrollingSpeed(700);
			
			// show section titles over "next section" arrow (not in use right now).
			switch(direction) {
			
				case 'down':
					if (index == 6) {
						Eton.deactivate ($('.next-section-navigation'));
					}
					if (sectionData.nextTitle) {
						$('.next-section-title').text(sectionData.nextTitle);
					} 
					//else {
						// Eton.deactivate($('.next-section-navigation'));
					// }
					break;
					
				case 'up':
					$('.next-section-title').text(sectionData.nextTitle);
					
					Eton.activate($('.next-section-navigation'));
					break;
			}
			
			// check section and do section specific things
			//console.info(sectionData);
			if (false && sectionData.id == "section-cta") {
				$.fn.fullpage.setAutoScrolling(false);
				$.fn.fullpage.setFitToSection(false);
			} else {
				$.fn.fullpage.setAutoScrolling(true);
				$.fn.fullpage.setFitToSection(true);
			}
		},
		
		/*
		 * Event "after load/show a section"
		 */
		'afterLoad': function(anchorLink, index){

			var sectionData = Eton.getFullpageSectionDataByIndex(index);
			
			$('body').addClass('fp-section-background-' + sectionData.backgroundType);
			$('body').data('fpSectionBackgroundType', sectionData.backgroundType);
			
			if (sectionData.nextTitle) {
				$('.next-section-title').text(sectionData.nextTitle);
			}
			
			if (sectionData.hasSlides) {
				$('body').addClass('fp-section-has-slides');
			} else {
				$('body').removeClass('fp-section-has-slides');
			}
		},

		/*
		 * Event "on leave a slide". This method is triggered for the currently visible slide!
		 */
		'onSlideLeave': function(anchorLink, index, slideIndex, direction, nextSlideIndex) {

			// section's index' start with 1 (first section)
			var section = $('.fp-section')[index-1];
			var sectionId = $(section).attr('id')

			// slide's index' start with 0 (first slide) 
			var slides = $('.fp-slide', section);
			var slide = slides[slideIndex];
			var nextSlide =  slides[nextSlideIndex];
			var totalSlides = parseInt(slides.length);

			$(section).removeClass(function(index, className) {
				return (className.match(/(^|\s)fp-active-slide-\S+/g) || []).join(' ');
			});
			
			$(section).addClass('fp-leaving-slide-' + (slideIndex+1));
			$(section).addClass('fp-active-slide-' + (nextSlideIndex+1));
			
			// Do section specific things
			switch (sectionId) {
			
				case 'section-data':
					//console.info(direction + ", slide: " + slideIndex + ", next slide: " + nextSlideIndex + ", total: " + totalSlides);
					if (slideIndex % 2 == 0) {
						
						// special state when switching from first slide (#0) to last slide (#15)
						if ((slideIndex == 0 && nextSlideIndex == (totalSlides-1) && direction == "right") || (direction == "left")) { 
							
							Eton.moveMultipleSlidesLeft();
						} else  {

							Eton.moveMultipleSlidesRight();
						}
					}
					break;
			}
			 
		},
		
		/*
		 * Event "after load/show a slide. This method is triggered for the currently visible slide!
		 */
		'afterSlideLoad': function(anchorLink, index, slideAnchor, slideIndex) {
			
			var section = $('.fp-section')[index-1];
			var sectionId = $(section).attr('id')
			
			var slide = $('.fp-slide')[slideIndex];
			
			if ($(slide).children().first().hasClass('slide-background')) {
				$('body').addClass('fp-section-fullscreen-slide');
			} else {
				$('body').removeClass('fp-section-fullscreen-slide');
			}
			
			//$(section).addClass('fp-active-slide-' + (slideIndex+1));
			$(section).removeClass(function(index, className) {
				return (className.match(/(^|\s)fp-leaving-slide-\S+/g) || []).join(' ');
			});
			
			// Do section specific things
			switch (sectionId) {
			
				case 'section-data':
					if (slideIndex % 2 == 1) {
						//Eton.moveMultipleSlidesRight()
					}
					break;
			}

		}
	});
	
	// initialize "next section" button for fullpage.js
	$('.next-section-navigation').on('click', function() {
		$.fn.fullpage.moveSectionDown();
	});


	// URL ?section=foo jumps to section foo
	// Used to make shortcut lilnk etonair.com/app work
	// (which gets redirected to etonair.com?section=app 
	// in .htaccess)
	var s = /section=([^&]+)/.exec(window.location.href);
	if (s && s.length > 1) {
		var section = s[1];
		if (section) {
			$.fn.fullpage.moveTo (section);
		}
	}
});
 
