/**
 * default.js
 * contains Javascripts for default fullpage pages
 *
 * @author J.Hahn <j.hahn@agentur-halma.de>
 * @version 2017-11-21
 */

$(document).ready(function() { 

	// Only on FAQ page...
	if ($('body.page-12').length == 0) {
		return;
	}

	
	/*
	 *  initialize fullpage.js
	 */
	$('#fullpage').fullpage({
		'verticalCentered': false,
		'css3': true,
		'navigation': false,
		'autoScrolling': false,
		'scrollBar': true,
		'fitToSection': false,
		'controlArrows': false
	});
	
	// initialize dynamic content links 
	// console.log ("initializing dynamic content links");
	$('.dynamic-content__link').each(function(index, element) {
		
		$(element).on('click', function(ev) {
			
			ev.preventDefault();
			
			var link = ev.currentTarget;
			var overviewPos = window.scrollY; //document.querySelector('#fullpage').scrollTop;

			$('.dynamic-content__wrapper').css({
				top: overviewPos + 'px'
			});
			
			$.ajax({
				method:'POST',
				data: {
					'action': 'showDetails',
					'id': $(link).data('record-id'),
					'ajax': 1
				}
			}).done(function(response) {
				$('.dynamic-content').html(response);
				$.fn.fullpage.moveSlideRight();
			});
		})
	});
	
	$('.dynamic-content__close').each(function(index, element) {
		
		$(element).on('click', function(ev) {
			$.fn.fullpage.moveSlideLeft();
		});
	});
});
 
